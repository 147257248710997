function commonFormatRuc(document) {
    let legacyRuc = false;
    const crossReference = {
        '00': '00', '10': '01', '11': '02', '12': '03', '13': '04',
        '14': '05', '15': '06', '16': '07', '17': '08', '18': '09',
        '19': '01', '20': '02', '21': '03', '22': '04', '23': '07',
        '24': '08', '25': '09', '26': '02', '27': '03', '28': '04',
        '29': '05', '30': '06', '31': '07', '32': '08', '33': '09',
        '34': '01', '35': '02', '36': '03', '37': '04', '38': '05',
        '39': '06', '40': '07', '41': '08', '42': '09', '43': '01',
        '44': '02', '45': '03', '46': '04', '47': '05', '48': '06',
        '49': '07'
    };

    const parts = document.split('-');
    if ((parts.length === 4 && parts[1] !== 'NT') || parts.length < 3 || parts.length > 5) {
        return ''; // RUC is not valid
    }

    const commonAddition = '0000005';
    const commonPad = '0'.repeat(4 - parts[1].length);
    let common = '';

    if (document[0] === 'E') {
        common = commonPad + commonAddition + '00' + '50' + '0'.repeat(3 - parts[1].length) + parts[1] + '0'.repeat(5 - parts[2].length) + parts[2];
    } else if (parts[1] === 'NT') {
        if (parts[3].length >= 6) {
            common = ('5' + String(parts[0]).padStart(2, '0') + '43' + String(parts[2]).padStart(3, '0') + parts[3]).padStart(20, '0');
        } else {
            common = '0'.repeat(8) + parts[0].padStart(2, '0') + '43' + parts[2].padStart(3, '0') + parts[3].substring(0, 5).padStart(5, '0');
        }
    } else if (parts[0].endsWith('AV')) {
        common = commonPad + commonAddition + '00'.repeat(2 - (parts[0].slice(0, -2)).length) + parts[0].slice(0, -2) + '15' + '0'.repeat(3 - parts[1].length) + parts[1] + '0'.repeat(5 - parts[2].length) + parts[2];
    } else if (parts[0].endsWith('PI')) {
        common = commonPad + commonAddition + '00'.repeat(2 - (parts[0].slice(0, -2)).length) + parts[0].slice(0, -2) + '79' + '0'.repeat(3 - parts[1].length) + parts[1] + '0'.repeat(5 - parts[2].length) + parts[2];
    } else if (parts[0] === 'PE') {
        common = commonPad + commonAddition + '00' + '75' + '0'.repeat(3 - parts[1].length) + parts[1] + '0'.repeat(5 - parts[2].length) + parts[2];
    } else if (document[0] === 'N') {
        common = commonPad + commonAddition + '00' + '40' + '0'.repeat(3 - parts[1].length) + parts[1] + '0'.repeat(5 - parts[2].length) + parts[2];
    } else if (parts[0].length > 0 && parts[0].length <= 2) {
        common = commonPad + commonAddition + parts[0].padStart(2, '0') + '00' + parts[1].padStart(3, '0') + parts[2].substring(0, 5).padStart(5, '0');
    } else {
        common = parts[0].padStart(10, '0') + parts[1].padStart(4, '0') + parts[2].padStart(6, '0');
        legacyRuc = (common[3] === '0' && common[4] === '0' && common[5] < '5'); // Check if old RUC
    }

    if (legacyRuc) {
        common = common.substring(0, 5) + (crossReference[common.substring(5, 7)] || common.substring(5, 7)) + common.substring(7);
    }

    return [common, legacyRuc];
}

function digitDv(legacyRuc, commonFormat) {
    let j = 2;
    let nsuma = 0;

    for (let i = commonFormat.length - 1; i >= 0; i--) {
        if (legacyRuc && j === 12) {
            legacyRuc = false;
            j--;
        }
        nsuma += j * (commonFormat.charCodeAt(i) - '0'.charCodeAt(0));
        j++;
    }

    const r = nsuma % 11;
    return r > 1 ? String.fromCharCode(48 + 11 - r) : '0';
}

/**
 * Calcular el dígito de verificación
 * @param {string} document Documento
 * @returns {number} Dígito de verificación
 */
export default function getDV(document) {
    const [common, legacyRuc] = commonFormatRuc(document);
    if (!common) {
        return '';
    }
    const dv1 = digitDv(legacyRuc, common);
    const dv2 = digitDv(legacyRuc, common + dv1);

    return dv1 + dv2;
}